<template>
    <div class="wrap">
        <div class="main_title">Product</div>
        <div class="tabs flex">
            <div @click="changeTab(sub)" :class="['tab_label', getSubActive(sub)]" v-for="(sub, idx) in subMenuList" :key="idx">{{sub.name}}</div>
        </div>
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'Product',
    data(){
        return {
            subMenuList: [
                {
                    name: 'Product',
                    path: '/manage/admin/product/product'
                },
                {
                    name: 'Product class',
                    path: '/manage/admin/product/product_class'
                },
                {
                    name: 'Brand',
                    path: '/manage/admin/product/brand'
                },
                {
                    name: 'Region',
                    path: '/manage/admin/product/region'
                },
            ]
        }
    },
    methods: {
        changeTab(sub){
            this.$router.push({path: sub.path})
        },
    },
    computed: {
        getSubActive(){
            return sub => {
                const currRoutes = this.$route.path.split('/')
                const currRoute = currRoutes[currRoutes.length - 1]
                const subRoutes = sub.path.split('/')
                const subRoute = subRoutes[subRoutes.length - 1]
                return {on : currRoute == subRoute}
            }
        }
    }
}
</script>
<style lang="stylus" scoped>
</style>